import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import media from '../../theme/media';

const Root = styled.div<Props>`
      ${p => media.greaterThan('medium')`
            padding-top: ${p.top || ''}px;
            padding-bottom: ${p.bottom || ''}px;
      `}
      padding-top: ${p => p.topMobile || p.top || ''}px;
      padding-bottom: ${p => p.bottomMobile || p.bottom || ''}px;
      .center {
          text-align: center;
      }
`;

const PaddedContainer: FunctionComponent<Props> = ({ children, ...props }) => (
    <Root {...props}>
        {children}
    </Root>
);

interface Props {
    top?: number;
    bottom?: number;
    topMobile?: number;
    bottomMobile?: number;
}

export default PaddedContainer;