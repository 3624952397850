import { TextField } from '@material-ui/core';
import debounce from 'lodash/debounce';
import { Autocomplete } from '@material-ui/lab';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import buildingsWebProxy from '../utils/buildingsWebProxy';
import { PrimaryButton } from './Button/Button';
import { PrimaryLinkButton } from './Button/LinkButton';
import Chevron from './Chevron';
import RegionTitle from './RegionTitle';
import { useHistory, useLocation } from 'react-router-dom';
import { useMountedState } from 'react-use';

const SelectionIcon = styled(Chevron)`
  width: 8px;
  height: 15px;
  margin: 0px 15px;
  &:hover {
    background-color: transparent;
  }; 
`;
const Description = styled.div`
  margin-bottom: 11px;
`;
const ButtonRow = styled.div`
  margin-top: 22px;
  margin-bottom: 22px;
`;

const PropertyAutocomplete: FunctionComponent<MyPropertyProps> = ({children, ...props }) => {

    const [building, setBuilding] = useState<Property|null>(null);
    const [loading, setLoading] = useState(true);

    const [buildings, setBuildings] = useState<Property[]>([]);
    const isMounted = useMountedState();
    const lastBuildingsRequest = useRef<Promise<any>|null>(null);
    const history = useHistory();
    const location = useLocation();

    async function getBuildings(input: any) {
      if (isMounted() && building?.address !== input) {
        setLoading(true);
        if (input.length >= 3) {
          const currentRequest = buildingsWebProxy.getBuildingsByAddress(input)
              .then(result => {
                if (isMounted() && currentRequest === lastBuildingsRequest.current) {
                  if (result.length > 0) {
                    setBuildings(result);
                    setLoading(false);
                  } else {
                    setBuildings([]);
                    setLoading(false);
                  }
                }
              })
              .catch(() => {
                setBuildings([]);
                setLoading(false);
              }); 
          lastBuildingsRequest.current = currentRequest;
        }
      }
    }

    const onSearchClicked = (idBuilding: number) => {
      history.replace(`${location.pathname}?idBuilding=${idBuilding}`);
      props.onSearchClicked!(idBuilding);
    };

    return (
        <div>
          {props.title && <RegionTitle compact>{props.title}</RegionTitle>}
          {children && <Description>{children}</Description>}
          <Autocomplete
            onChange={(event: any, newValue: Property | null) => {
              setBuilding(newValue);
            }}
            id="address-autocomplete"
            options={buildings}
            getOptionLabel={(option) => option.address}
            getOptionSelected={(option, val) => option.id === val.id}
            noOptionsText="Es wurde kein Objekt gefunden."
            clearOnEscape={false}
            clearOnBlur={false}
            loading={loading}
            loadingText="Die Ergebnisse werden angezeigt, wenn Sie die Adresse eingeben"
            popupIcon={<SelectionIcon direction="down"/>}
            onInputChange={debounce((ev, input) => getBuildings(input), 500)}
            filterOptions={(options, state) => options}
            renderInput={(params) => <TextField {...params} label="Adresse" placeholder="Geben Sie bitte Ihre Adresse ein" variant="outlined"/>}
          />
          <ButtonRow>
            {props.action === 'show' && props.onSearchClicked &&
            <PrimaryButton onClick={() => onSearchClicked(building ? building.id : 0)}>Anzeigen</PrimaryButton>}
            {props.action === 'link' && 
            <PrimaryLinkButton to={`/hausverwaltung/meine-liegenschaft?idBuilding=${building?.id}`} follow>{props.buttonText}</PrimaryLinkButton>}
          </ButtonRow>
          
      </div>
    );
};

export interface MyPropertyProps {
    action: 'show' | 'link';
    title?: string;
    buttonText: string;
    onSearchClicked?: (id: number) => void;
}

export interface Property {
    id: number;
    address: string;
  }

export default PropertyAutocomplete;