import React, { useState, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
import Container from '../../components/layout/Container';
import { colsToWidth } from '../../utils/themeHelpers';
import heroImg from '../../assets/hero/my-property-hero.jpg';
import media from '../../theme/media';
import portalImage from '../../assets/PortalThumbnail.png';
import { PrimaryLinkButton, SecondaryLinkButton } from '../../components/Button/LinkButton';
import ContentPage from '../../components/layout/ContentPage';
import StLink from '../../components/StLink';
import RegionTitle from '../../components/RegionTitle';
import buildingsWebProxy, { ManualViewModel, PropertyDetail as PropertyDetailViewModel } from '../../../src/utils/buildingsWebProxy';
import { getFeminineRoleLabel, normalizePhoneNumber } from '../../utils/stringHelper';
import fallBackImage from '../../assets/BuildingFallBackImage.png';
import PropertyAutocomplete from '../../components/PropertyAutocomplete';
import { addToPublicFileUrl } from '../../utils/publicFileHelper';
import VideoEmbed from '../../components/VideoEmbed';
import LoadMoreProgressIndicator from '../../components/LoadMoreProgressIndicator';
import { adaptFriedenEmail } from '../../utils/emailHelper';

const AltContainer = styled(Container)`
  background-color: ${p => p.theme.color.backgroundAlt};
  ${p => media.greaterThan('navMob')`
    background-color: ${p.theme.color.background};
  `}
`;
const PortalTrailer = styled.div`
    margin: 105px 0 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content auto;
    border-radius: 10px;
    padding: 35px 0px;
    background-color: ${p => p.theme.color.backgroundLight};
    align-items: center;
    ${media.greaterThan('navMob')`
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: min-content auto;
    `}
    ${media.greaterThan('small')`
      padding: 35px 30px;
    `}
`;
const Thumbnail = styled.div`
    grid-column: 1/6;
    grid-row: 1/1;
    ${media.greaterThan('navMob')`
        grid-column: 1/3;
    `}
`;
const Image = styled.img`
    display: block;
    width: 100%;
    margin: 0;
`;
const FallBackImage = styled.img`
  width: 100%;
  height: auto;
`;
const FallBackContacts = styled.div`
  grid-column: 1/3;
`;
const Description = styled.div`
    grid-column: 3/6;
    grid-row: 2/2;
    display: grid;
    align-content: space-between;
    padding: 0px;
    grid-row-gap: 20px;
    padding-bottom: 0;
    h2 {
        margin-bottom: 20px;
    };
    ${media.greaterThan('navMob')`
        grid-row: 1/1;
        padding: 0px 30px;
    `}
`;
const MobileTitle = styled(RegionTitle)`
    display: block;
    margin-top: 15px;
    text-align: center;
    ${media.greaterThan('navMob')`
        display: none;
    `}
`;
const LargeTitle = styled(RegionTitle)`
    display: none;
    ${media.greaterThan('navMob')`
        display: block;
    `}
`;
const RightAlign = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: end;
`;
const CenterAlign = styled.div`
  margin: 0px auto;
`;
const MyPropertySection = styled.div`
  display: grid;
  p{
    padding-right: 30px;
  }
  max-width: ${p => colsToWidth(p.theme, 9)}px;
`;
const BuildingWrapper = styled.div`
  padding-top: ${p => p.theme.header.heightMob + p.theme.header.infoStripHeight}px;
  margin-top: ${p => (p.theme.header.heightMob + p.theme.header.infoStripHeight) * -1}px;
  ${p => media.greaterThan('navMob')`
    padding-top: ${p.theme.header.height + p.theme.header.infoStripHeight}px;
    margin-top: ${(p.theme.header.height + p.theme.header.infoStripHeight) * -1}px;
  `}
`;
const PropertyHolder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: ${p => colsToWidth(p.theme, 9)}px;
  width: 100%;
  ${media.greaterThan('navMob')`
    grid-template-columns: repeat(10, 1fr);
  `}
`;
const PropertyDetail = styled.div`
  display: grid;
  grid-column: 1;
  justify-items: center;
  justify-items: start;
  grid-column-gap: 25px;
  ${media.greaterThan('navMob')`
    grid-column: 1/10;
    grid-template-columns: repeat(10, 1fr);
  `}
`;
const PropertyThumbnail = styled.div`
  grid-row: 1;
  max-width: 184px;
  ${media.greaterThan('navMob')`
    grid-column: 1/4;
    grid-row: 1/3;
  `}
`;
const PropertyTitle = styled.h3`
  grid-column: 1;
  grid-row: 2;
  margin-top: 20px;
  line-height: 20px;
  margin-bottom: 24px;
  ${media.greaterThan('navMob')`
    grid-column: 4/11;
    grid-row: 1;
  `}
`;
const PropertyContacts = styled.div`
  grid-column: 1;
  grid-row: 3;
  display: grid;
  grid-auto-rows: 1fr;
  grid-row-gap: ${p => p.theme.grid.gutter}px;
  ${media.greaterThan('navMob')`
    grid-column: 4/11;
    grid-row: 2;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: min-content;
    grid-row-gap: 25px;
    grid-column-gap: 25px;
  `}
`;
const ManualsSection = styled.div`
  display: grid;
  grid-column: 1/10;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
  grid-column-gap: ${p => p.theme.grid.gutter}px;
  justify-items: center;
  p {
    margin-bottom: 0;
  }
  ${media.greaterThan('navMob')`
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: min-content;
    grid-auto-flow: row;
    justify-items: start;
    justify-content: space-between;
  `}
`;
const ManualRoot = styled.div`
  grid-template-rows: max-content max-content;
  grid-auto-flow: row;
  grid-row-gap: 15px;
  position: relative;
  width: 100%;
  h3{
    margin: 0;
    text-transform: none;
  }
  p{
    margin: 0;
  }
  i{
    font-size: smaller;
  }
  grid-template-columns: 1fr;
  display:grid;
`;
const ButtonRow = styled.div`
  padding: 13px 0; 
  display: grid;
  grid-auto-flow: row;
  column-gap: ${p => p.theme.grid.gutter}px;
  grid-row-gap: 20px;
  grid-auto-columns: min-content;
  ${media.greaterThan('small')`
      grid-auto-flow: column;
  `}
`;

const Manual: FunctionComponent<{ manual: ManualViewModel }> = ({ manual }) => {
  // TODO: video provider should be in ReCore
  return (
    <ManualRoot>
      <VideoEmbed src={manual.manualUrl} />
      <h3>{manual.title}</h3>
      {manual.note && <i>{manual.note}</i>}
    </ManualRoot>);
};

const PropertyManagementIndex = ({ model }) => {

  const [building, setBuilding] = useState<PropertyDetailViewModel>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (model) {
      setBuilding(model);
    }
  }, [model]);

  useEffect(() => {
    document.getElementById('building')?.scrollIntoView();
  }, [building]);

  function showPropertyDetail(idBuilding: number) {
    if (idBuilding !== null && idBuilding !== undefined && idBuilding !== building?.id) {
      setIsLoading(true);
      buildingsWebProxy.getBuildingDetailsById(idBuilding).then((data) => {
        setIsLoading(false);
        setBuilding(data as PropertyDetailViewModel);
      });
    }
  }

  return (
    <ContentPage image={heroImg} title="Meine Liegenschaft">
      <Container>
        <RightAlign>
          <MyPropertySection>
            <PropertyAutocomplete action="show" onSearchClicked={showPropertyDetail} buttonText="Anzeigen">
              <p>Geben Sie Ihre Adresse ein, um die öffentlichen Informationen zu Ihrer Immobilie anzuzeigen. Sollten Sie
              an detaillierten personalisierten Informationen zu Ihrer Bewohnung interessiert sein, besuchen
                Sie bitte unser <StLink to="https://portal.frieden.at/" external follow>Bewohnerportal</StLink>. </p>
            </PropertyAutocomplete>
          </MyPropertySection>
        </RightAlign>
        {isLoading && <CenterAlign>
          <LoadMoreProgressIndicator />
        </CenterAlign>}
        {building && <BuildingWrapper id="building">
          <RegionTitle topOffset={35}>KontaktDaten</RegionTitle>
          <RightAlign>
            <PropertyHolder>
              <PropertyDetail>
                <PropertyThumbnail>
                  {building.mainPhoto ? <Image src={addToPublicFileUrl(building.mainPhoto, { width: 368 })} alt={building.address}/> :
                    <FallBackImage src={fallBackImage} alt={'Bild des Gebäudes'}/>}
                </PropertyThumbnail>
                <PropertyTitle>{building.address}</PropertyTitle>
                <PropertyContacts>
                  {building.responsibilities.length > 0 ? building.responsibilities.map(responsibility =>
                    !!(responsibility.role.roleCode === 'Housemanager' || responsibility.role.roleCode === 'HousemanagerAssistant') 
                    && responsibility.persons.map(person =>
                      <div key={`${responsibility.role.roleCode}-${person.id}`}>
                        {person.gender === 'Female' ? getFeminineRoleLabel(responsibility.role.roleCode) : responsibility.role.label}<br/>
                        <strong>{person.firstName} {person.lastName}</strong><br/>
                        {!!person.phone && <StLink to={`tel:${normalizePhoneNumber(person.phone)}`} external>{person.phone}</StLink>}<br/>
                        <StLink to={`mailto:${adaptFriedenEmail(person.mail)}`} external>{adaptFriedenEmail(person.mail)}</StLink>
                      </div>
                    )
                  ) :
                    <FallBackContacts>
                      Für das ausgewählte Objekt liegen keine Daten vor.
                      Bitte kontaktieren Sie uns über die allgemeinen <StLink to="/kontakt">Kontaktdaten</StLink>.
                    </FallBackContacts>
                  }
                </PropertyContacts>
              </PropertyDetail>
            </PropertyHolder>
          </RightAlign>
          {building.manuals.length > 0 && <RegionTitle topOffset={53}>Bedienungsanleitungen</RegionTitle>}
          {building.manuals.length > 0 && <RightAlign>
            <PropertyHolder>
              <ManualsSection>
                {building.manuals.map(manual => <Manual key={manual.manualUrl} manual={manual} />)}
              </ManualsSection>
            </PropertyHolder>
          </RightAlign>}
        </BuildingWrapper>}
      </Container>
      <AltContainer>
        <PortalTrailer>
          <Thumbnail>
            <MobileTitle>Erfahren sie mehr im BEWOHNERPORTAL</MobileTitle>
            <Image src={portalImage} alt="Screenshot des Bewohnerportals mit den aufgelisteten Dokumenten der Jahresabrechnung."/>
          </Thumbnail>
          <Description>
            <div>
              <LargeTitle>Erfahren sie mehr im BEWOHNERPORTAL</LargeTitle>
                    Auf dieser digitalen Service-Plattform können Sie derzeit Ihre Jahresabrechnung samt allen zugehörigen
                     Belegen einsehen sowie Ihr Bewohner-Profil (Telefonnummer, E-Mail-Adresse etc.) auf Richtigkeit und Vollständigkeit prüfen und
                     allfällige Änderungen bekannt geben. Darüber hinaus werden laufend weitere Funktionalitäten freigeschalten.
                </div>
            <ButtonRow>
              <PrimaryLinkButton to="https://portal.frieden.at" external>ZUM BEWOHNERPORTAL</PrimaryLinkButton>
              <SecondaryLinkButton to="/hausverwaltung/bewohnerportal">INFO</SecondaryLinkButton>
            </ButtonRow>
          </Description>
        </PortalTrailer>
      </AltContainer>
    </ContentPage>
  );
};

export default PropertyManagementIndex;