// TODO: tests

import siteConfig from '../site.config';

const monthNames = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
];

const dateHelper = {
    getDayDiff(dateA: Date, dateB: Date): number {
        dateA = typeof(dateA) ===  'string' ? stringToDate(dateA) : dateA;
        dateB = typeof(dateB) ===  'string' ? stringToDate(dateB) : dateB;
        const diffMillis = dateA.getTime() - dateB.getTime();
        return Math.ceil(diffMillis / 1000 / 60 / 60 / 24);
    },

    formatQuarterYear(date: Date): string {
        date = typeof(date) ===  'string' ? stringToDate(date) : date;
        return `${Math.ceil((date.getMonth() + 1) / 3)}. Quartal ${date.getFullYear()}`;
    },

    formatMonthYear(date: Date): string {
        date = typeof(date) ===  'string' ? stringToDate(date) : date;
        return `${getMonthName(date)} ${date.getFullYear()}`;
    },

    formatDate(date: Date): string {
        date = typeof(date) ===  'string' ? stringToDate(date) : date;
        // dd.mm.yyyy
        return `${formatTwoDigits(date.getDate())}.${formatTwoDigits(date.getMonth() + 1)}.${date.getFullYear()}`;
    },
};

function formatTwoDigits(num: number): string {
    return `${num <= 9 ? '0' : ''}${num}`;
}

function stringToDate(dateString: any) {
    return new Date(dateString);
}

function getMonthName(date: Date) {
    return monthNames[date.getMonth()];
}

export default dateHelper;