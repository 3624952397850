import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  justify-content: center;
  padding: ${p => p.theme.grid.gutter}px;
`;

const LoadMoreProgressIndicator = () => (
  <Root>
    <CircularProgress color="primary" />
  </Root>
);

export default LoadMoreProgressIndicator;