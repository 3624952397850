import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Container from '../../components/layout/Container';
import media from '../../theme/media';
import RegionTitle from '../../components/RegionTitle';
import { PrimaryLinkButton } from '../../components/Button/LinkButton';
import logo from '../../assets/LogoFRInformatikWithoutBorder.svg';

const Content = styled.div`
      padding: 49px 0;
`;

const SubTitle = styled.div`
      text-align: center;
      margin-bottom: 49px;
`;

const Main = styled.div`
      display: grid;
      justify-items: center;
      grid-row-gap: 20px;

      ${media.greaterThan('medium')`
            grid-auto-flow: column;
            justify-content: center;
            grid-template-columns: auto minmax(1px, 592px);
            grid-column-gap: 43px;
      `}
`;

const Logo = styled.img`
      width: 161px;
`;

const TextWithButton = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
`;

const ButtonContainer = styled.div`
      margin-top: 20px;
`;

const FrInformatikSection: FunctionComponent = () => (
      <Container background="alt">
            <Content>
                  <RegionTitle align="center" compact>Frieden Informatik</RegionTitle>
                  <SubTitle>IT-Betreuung und Softwarelösungen für den Wohnbau</SubTitle>
                  <Main>
                        <Logo src={logo} alt="logo FR Informatik" />
                        <TextWithButton>
                              <div>Als Tochtergesellschaft der Wohnbaugenossenschaft FRIEDEN spezialisiert sich FRIEDEN Informatik auf leistungsfähige Softwarelösungen und fundierte IT-Betreuung für gemeinnützige und gewerbliche Wohnbauunternehmen.</div>
                              <ButtonContainer>
                                    <PrimaryLinkButton external to="https://frieden-informatik.at">zur Webseite</PrimaryLinkButton>
                              </ButtonContainer>
                        </TextWithButton>
                  </Main>
            </Content>
      </Container>
);

export default FrInformatikSection;
