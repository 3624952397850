import WebProxyClient from './webProxyClient';

const client = new WebProxyClient('/hausverwaltung');

const buildingsWebProxy = {
    getBuildingsByAddress: (searchString: string): Promise<Property[]> => client.get('/GetBuildingsByAddressAsync', ('qs=' + searchString)),
    getBuildingDetailsById: (idBuilding: number): Promise<PropertyDetail> => client.get('/GetBuildingDetailsByIdAsync', ('idBuilding=' + idBuilding)),
};

export interface PropertyDetail {
    id: number;
    address: string;
    mainPhoto: string;
    responsibilities: {
        role: {
            roleCode: string;
            label: string;
        };
        persons: {
            id: number;
            preTitle: string;
            firstName: string;
            lastName: string;
            gender: 'Male' | 'Female';
            jobDesignation: string;
            photo: string;
            postTitle: string;
            phone: string;
            mail: string;
            faxNumber: string;
            office: string;
            department: string;
            isHeadOfDepartment: boolean;
        }[];
    }[];
    manuals: ManualViewModel[];
    suppliers: {
        id: number;
        profession: {
            id: number;
            code: string;
            label: string;
        };
        organisationName: string;
    }[];
}

export interface Property {
    id: number;
    address: string;
}

export interface ManualViewModel {
    type: string;
    manualUrl: string;
    title: string;
    note?: string;
}

export default buildingsWebProxy;