import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import CookieBlockedMessage from './CookieBlockedMessage';
import { CheckVideoEmbedCookieConsent, EVideoProvider } from '../utils/videoEmbedCookieHelper';

const VideoWrap = styled.div`
    position: relative;
    width: 100%;
    padding-top: 75%;
`;

const Video = styled.iframe`
    position: absolute;
    top:0;
    margin-bottom: 0;
    width: 100%;
    height: 100%;
`;

const VideoEmbed: FunctionComponent<Props> = (props) => {
    const isAllowed = CheckVideoEmbedCookieConsent(props.src, props.provider);

    return isAllowed ?
        <VideoWrap>
            <Video src={props.src} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
        </VideoWrap> :
        <CookieBlockedMessage anchor="ThirdPartyEmbeds" />;
};

export interface Props {
    src: string;
    provider?: EVideoProvider;
}

export default VideoEmbed;