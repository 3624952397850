export function normalizePhoneNumber(p: string): string {
    return p.replace(/(\s|-)/g, '');
}

export function formatBool(value: boolean) {
    return value ? 'Ja' : 'Nein';
}

export function getFeminineRoleLabel(roleCode: string) {
    return feminineRoles[roleCode];
}

const feminineRoles: {[code: string]: string} = {
    'Housemanager': 'Hausverwalterin',
    'HousemanagerAssistant': 'Sachbearbeiterin',
    'Sales': 'Verkaufsleiterin',
    'ConstructionEngineer': 'Technikerin',
    'ConstructionAccountant': 'Baubuchhalterin',
    'TenantAccountant': 'Bestandnehmerbuchhalterin'
};