import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<FocusedImageProps>`
    width: 100%;
    height: 100%;
    background-image: url(${p => p.src});
    background-size: cover;
    background-position: ${p => p.x + '% ' + p.y + '%'};
    transform-origin: ${p => p.x + '% ' + p.y + '%'};
`;

const FocusedImage: FunctionComponent<FocusedImageProps> = ({src, x, y, alt, className}) => {
    const [focalPoint, setFocalpoint] = useState({x: 0, y: 0});

    useEffect(() => {
        const xperc = getPercentageValue(x);
        const yperc = getPercentageValue(-y);
        setFocalpoint({x: xperc, y: yperc});
    }, [x, y, src]);

    return <Wrapper src={src} x={focalPoint.x} y={focalPoint.y} className={className}>
        <span aria-label={alt} role="img"/>
    </Wrapper>;
};

export default FocusedImage;

const getPercentageValue = (input: number): number => {
    return (((input + 1) / 2) * 100) || 0;
};

export interface FocusedImageProps {
    src: string;
    x: number;
    y: number;
    className?: string;
    alt?: string;
}