import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import media from '../../theme/media';
import { SecondaryLinkButton, PrimaryLinkButton } from '../Button/LinkButton';
import RegionTitle from '../RegionTitle';
import dateHelper from '../../utils/dateHelper';
import StLink from '../StLink';
import FocusedImage from '../Image/FocusedImage';

const PostHolder = styled.div<Props>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content;
  border-bottom: ${p => p.doubleColumn ? `1px solid ${p.theme.color.text}` : 'none'};
  ${p => media.greaterThan('medium')`
    border-top: ${p.doubleColumn ? `1px solid ${p.theme.color.text}` : 'none'};
    grid-column-gap: ${p.theme.grid.gutter}px;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
  `}
  overflow: hidden;
  height: 100%;
`;
const PostContent = styled.div<Props>`
  border-bottom: ${p => p.doubleColumn ? 'none' : `1px solid ${p.theme.color.text}`};
  grid-row: 2;
  padding: ${p => p.theme.grid.gutter}px 0;
  display:grid;
  min-height: 311px;
  ${p => media.greaterThan('medium')`
    border-top: ${p.doubleColumn ? 'none' : `1px solid ${p.theme.color.text}`};
    grid-column: 1;
    grid-row: 1;
    height: 100%;
  `}
`;
const PostThumbnailHolder = styled(StLink)<{doubleColumn?: boolean}>`
  align-self: center;
  grid-row: 1;
  ${media.greaterThan('medium')`
    grid-column: 2;
  `};
  grid-column: 1;
  height: ${p => p.doubleColumn ? '60%' : '100%'};
  min-height: ${p => p.doubleColumn ? '200px' : '350px'};
  overflow:hidden;
`;
const PostThumbnail = styled(FocusedImage)`
  min-height:100%;
  min-width: 100%;
  transition: 0.5s ease-in-out;
  &:hover{
    transform: scale(1.2);
  }
`;
const List = styled.div<Props>`
  display:grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-row-gap: 40px;
  grid-column-gap: ${p => p.theme.grid.gutter}px;
  ${p => media.greaterThan('navMob')`
    grid-template-columns: ${p.doubleColumn ? '1fr 1fr' : '1fr'};
  `};
    h2, h3, h4, p {
      text-transform: none;
    };
`;
const PostDetailButton = styled(SecondaryLinkButton)`
  align-self: end;
  justify-self: start;
`;
const Title = styled(RegionTitle)`
    justify-self: center;
`;
const ShowAllButton = styled(PrimaryLinkButton)`
    justify-self: center;
`;
const Wrapper = styled.div`
    display:grid;
    grid-row-gap: 50px;
`;

const PostsList: FunctionComponent<Props> = ({ items, doubleColumn, showAllButton, title, category }) => {

  let excerptLen = 250;
  if (doubleColumn) {
    excerptLen = 120;
  }

    return (
        <Wrapper>
            {title && <Title compact>{title}</Title>}
            <List items={items} doubleColumn={doubleColumn}>
                {items.map((postItem, index) =>
                    <PostHolder key={index}  doubleColumn={doubleColumn} items={items}>
                      <PostContent  doubleColumn={doubleColumn} items={items}>
                        <div>
                          {category === PostCategory.news && <p><strong>{dateHelper.formatDate(postItem.releaseDate)}</strong></p>}
                          <StLink to={`/unternehmen/${category === PostCategory.news ? 'news' : 'blog'}/${encodeURIComponent(postItem.alias)}`}><h2>{postItem.title}</h2></StLink>
                          <p>{postItem.excerpt?.length > excerptLen ? `${postItem.excerpt.slice(0, excerptLen)}...` : postItem.excerpt}</p>
                        </div>
                        <PostDetailButton to={`/unternehmen/${category === PostCategory.news ? 'news' : 'blog'}/${encodeURIComponent(postItem.alias)}`}>Weiterlesen</PostDetailButton>
                      </PostContent>
                      <PostThumbnailHolder to={`/unternehmen/${category === PostCategory.news ? 'news' : 'blog'}/${encodeURIComponent(postItem.alias)}`} doubleColumn={doubleColumn}>
                        <PostThumbnail src={postItem.thumbnailImageUrl} x={postItem.thumbnailImageFocalPointX} y={postItem.thumbnailImageFocalPointY}/>
                      </PostThumbnailHolder>
                    </PostHolder>
                )}
            </List>
            {showAllButton && <ShowAllButton to={`/unternehmen/${category === PostCategory.news ? 'news' : 'blog'}`}>Alle Ansehen</ShowAllButton>}
        </Wrapper>
        
    );
};

interface Props {
  items: PostItem[];
  doubleColumn?: boolean | false;
  showAllButton?: boolean | false;
  title?: string;
  newsThumbnailSrc?: string;
  category?: PostCategory;
}

export enum PostCategory {
  'news' = 1,
  'blog' = 2
}
  
export interface PostItem {
  id: number;
  releaseDate: Date;
  title: string;
  thumbnailImageUrl: string;
  thumbnailImageFocalPointX: number;
  thumbnailImageFocalPointY: number;
  excerpt: string;
  alias: string;
}

export default PostsList;