import { DependencyList, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import theme from '../theme/theme';

// TODO: rename, because of anchor scrolling

export default function scrollToTopOnMount(options?: Options) {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    const targetElm = hash && document.getElementById(hash.substring(1));
    if (targetElm) {
      window.scrollTo(0, targetElm.offsetTop - theme.header.height);
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash].concat(options?.deps || []));
}

interface Options {
  deps?: DependencyList;
}