import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

const Title = styled.h1<{ compact?: boolean, contentTitle?: boolean }>`
    margin-bottom: ${p => p.compact ? '0' : '33px'}; // TODO: what is correct? It is not consistent in the XD
    text-transform: ${p => p.contentTitle ? 'none' : 'uppercase'};
`;

const SubTitle = styled.span` // TODO: refactor with typography.js/h2
    display: block;
    font-size: 18px;
    line-height: 24px;
    color: ${p => p.theme.color.primary};
    font-weight: bold;
    text-transform: uppercase;
    margin: 9px 0 0 0;
`;

const SectionTitle: FunctionComponent<Props> = ({ className, children, subTitle, compact, contentTitle }) => (
    <Title compact={compact} className={className} contentTitle={contentTitle}>{children}<SubTitle>{subTitle}</SubTitle></Title>
);

interface Props {
    subTitle?: string;
    className?: string;
    compact?: boolean;
    contentTitle?: boolean;
}

export default SectionTitle;
