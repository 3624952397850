import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Container from './Container';
import Seo, { SeoProps } from '../Seo';
import Hero from '../Hero';
import SectionTitle from '../SectionTitle';
import NavBar, { NavBarProps } from '../NavBar';
import scrollToTopOnMount from '../scrollToTopOnMount';

const Root = styled.div`
`;

const Title = styled(SectionTitle)`
    margin-top: 55px;
`;

const ContentPage: FunctionComponent<Props> = ({ children, ...props }) => {

    if (props.startOnTop) {
        scrollToTopOnMount();
    }

    return (
        <Root>
            <Seo {...props} title={props.metaTitle || props.title} />
            {props.image && <Hero src={props.image} x={props.imageFocalPoint ? props.imageFocalPoint.x : 0} y={props.imageFocalPoint ? props.imageFocalPoint.y : 0} />}
            <Container>
                <NavBar {...props} />
                <Title compact={props.compactTitle} subTitle={props.subTitle} contentTitle={props.contentTitle}>{props.title}</Title>
            </Container>
            {children}
        </Root>
    );
};

export interface Props extends SeoProps, NavBarProps {
    metaTitle?: string;
    subTitle?: string;
    title: string;
    compactTitle?: boolean;
    contentTitle?: boolean;
    startOnTop?: boolean;
}

export default ContentPage;
