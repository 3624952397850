import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

const Title = styled.h2<Props>`
    margin-top: ${p => p.topOffset ? `${p.topOffset}px` : '0'};
    margin-bottom: ${p => p.compact ? '0' : '50px'};
    text-align: ${p => p.align};
`;

const SubTitle = styled.span`
    display: block;
    line-height: 24px;
    color: ${p => p.theme.color.primary};
    font-weight: bold;
    text-transform: uppercase;
    margin: 9px 0 0 0;
`;

const RegionTitle: FunctionComponent<Props> = ({ align, className, children, subTitle, compact, topOffset }) => (
    <Title align={align} compact={compact} className={className} topOffset={topOffset}>{children}<SubTitle>{subTitle}</SubTitle></Title>
);

interface Props {
    subTitle?: string;
    className?: string;
    compact?: boolean;
    topOffset?: number;
    align?: 'left' | 'center' | 'right';
}

export default RegionTitle;
