export function addToPublicFileUrl(baseUrl: string, { width, height }: { width?: number; height?: number; }): string {
    if (width && height) {
        throw new Error('Both width and height cannot be specified.');
    }

    if (!width && !height) {
        throw new Error('Width or height must be specified.');
    }

    return `${baseUrl}?${width ? `width=${width}` : `height=${height}`}`;
}
// TODO: use this everywhere where public file urls are manipulated
