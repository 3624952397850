import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import media from '../theme/media';
import { BackLinkButton, ForwardLinkButton } from './Button/LinkButton';

const Root = styled.div`
    margin-top: 38px;
    display: flex;
    justify-content: space-between;
`;

const Desktop = styled(Root)`
   display: none;
   ${media.greaterThan('small')`
        display: flex;
  `}
`;

const Mobile = styled(Root)`
   display: flex;
   ${media.greaterThan('small')`
        display: none;
  `}
`;

const NavBar: FunctionComponent<NavBarProps> = props => {
    return (props.prevLink || props.nextLink) ? (
        <>
            <Desktop>
                {props.prevLink && <BackLinkButton to={props.prevLink.to}>{props.prevLink.title}</BackLinkButton>}
                {props.nextLink && <ForwardLinkButton to={props.nextLink.to}>{props.nextLink.title}</ForwardLinkButton>}
            </Desktop>
            <Mobile>
                {props.prevLink && <BackLinkButton to={props.prevLink.to}>{props.prevLink.mobileTitle || props.prevLink.title}</BackLinkButton>}
                {props.nextLink && <ForwardLinkButton to={props.nextLink.to}>{props.nextLink.mobileTitle || props.nextLink.title}</ForwardLinkButton>}
            </Mobile>
        </>
    ) : null;
};

export interface NavBarProps {
    prevLink?: Nav;
    nextLink?: Nav;
}

interface Nav {
    title: string;
    mobileTitle?: string;
    to: string;
    // TODO: external if required
}

export default NavBar;
