import React, { FunctionComponent } from 'react';
import styled, { DefaultTheme, ThemeProps } from 'styled-components';
import media from '../theme/media';
import FocusedImage from './Image/FocusedImage';

const Root = styled(FocusedImage)<Props>`
    width: 100%;
    height: ${p => p.height === 'large' ? `${p.theme.hero.largeHeightMob}px` : `${p.theme.hero.defaultHeightMob}px`};
    position: relative;

    ${media.greaterThan<Props>('navMob')`
        height: ${p => {
            switch (p.height) {
                case 'full':
                    return `calc(100vh - ${p.theme.header.height}px)`;
                case 'large':
                    return `${p.theme.hero.largeHeight}px`;
                case 'fill-parent':
                    return '100%';
                default:
                    return `${p.theme.hero.defaultHeight}px`;
            }
        }};
    `}
`;

const Hero: FunctionComponent<Props> = ({ children, ...props }) => (
    <Root {...props}>
        {children}
    </Root>
);

interface Props {
    className?: string;
    src: string;
    height?: 'default' | 'large' | 'full' | 'fill-parent';
    x: number; 
    y: number;
}

export default Hero;
