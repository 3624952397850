import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { PrimaryLinkButton } from '../Button/LinkButton';
import dateHelper from '../../utils/dateHelper';
import StLink from '../StLink';
import projectFallbackImg from '../../assets/hero/project-detail-default-hero.jpg';
import { addToPublicFileUrl } from '../../utils/publicFileHelper';
import { SalesProjectListItemViewModel } from '../../utils/projectWebProxy';
import FocusedImage from '../Image/FocusedImage';

const DashboardTile = styled.div<{ tileWidth?: number }>`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: grid;
  width: 100%;
  max-width: ${p => p.tileWidth ? `${p.tileWidth}px` : '100%'};
  background-color: ${p => p.theme.color.backgroundAlt};
  height: 100%;
  grid-template-rows: min-content 1fr;
  &:after{
    content: '';
    position: absolute;
    width: 56px;
    height: 51px;
    border-top: 51px solid ${p => p.theme.color.primary};
    border-left: 56px solid transparent;
    top: 0;
    right: 0;
    z-index: 5;
  }
`;
const ProjectImageWrapper = styled.div<{hover: boolean }>`
    width: 100%;
    height: 229px;
    position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: ${p => p.hover ? 0.5 : 0};
    background-color: ${p => p.theme.color.primary};
    transition: 0.8s ease-in-out;
    pointer-events: none;
  }
`;
const ProjectImage = styled(FocusedImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;
const ProjectInfo = styled.div`
  padding: 17px 16px;
  display: grid;
  grid-template-rows: 1fr min-content min-content 1fr;
`;
const ProjectLink = styled(StLink)`
  max-width: 100%;
  overflow:hidden;
  text-overflow: ellipsis;
`;
const Address = styled.h2`
  text-transform: none;
  font-size: 21px;
  margin-bottom: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const ProjectProposalTypes = styled.div`
  align-self: start;
`;
const ProjectOtherInfo = styled.div`
  align-self: start;
  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  p {
    margin-bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
const ProjectButton = styled.div`
  align-self: end;
`;

export function toProjectTiles(projects: SalesProjectListItemViewModel[] | undefined): ProjectTile[] {
  if (projects === undefined) {
    return [];
  }
  return projects.map(project => {
    return ToProjectTile(project);
  });
}

export function ToProjectTile(project: SalesProjectListItemViewModel): ProjectTile {

  const projectBuildingConstructionDates = project.buildings.map(building => {
    return { address: building.label, constructionFinishedOn: building.constructionFinishedOn };
  });
  const proposalTypes = project.proposalTypes.map(ptype => {
    return ptype.label;
  }).join(', ');

  return {
    id: project.id,
    alias: project.alias,
    cityPostCode: `${project.postCode} ${project.city}`,
    numberOfLivingUnits: project.numberOfLivingUnits,
    numberOfOnlineLivingUnits: project.numberOfOnlineLivingUnits,
    mainPhotoUrl: project.mainPhoto,
    mainPhotoFocalPointX: project.mainPhotoFocalPointX,
    mainPhotoFocalPointY: project.mainPhotoFocalPointY,
    constructionFinishedOn: project.constructionFinishedOn,
    street: project.street,
    buildingsConstructionDates: projectBuildingConstructionDates,
    proposalTypes
  };
}

const ProjectDashboardTile: FunctionComponent<Props> = ({ tileWidth, project, returnUrl }) => {

  const currentDate = new Date();
  const projectConstructionDate = project.constructionFinishedOn ?
    dateHelper.getDayDiff(project.constructionFinishedOn, currentDate) <= 0 ?
      'Beziehbar: ab sofort' :
      dateHelper.getDayDiff(project.constructionFinishedOn, currentDate) <= 365 ?
        'Vsl. Fertigstellung: ' + dateHelper.formatMonthYear(project.constructionFinishedOn) :
        'Vsl. Fertigstellung: ' + dateHelper.formatQuarterYear(project.constructionFinishedOn)
    : '';

  const [tileHover, setTileHover] = useState(false);

  const constructionDatesAndAddressesBase = 'Vsl. Fertigstellung: ';
  const constructionDatesAndAddresses = project.buildingsConstructionDates.length <= 1 ?
    projectConstructionDate :
    constructionDatesAndAddressesBase.concat(project.buildingsConstructionDates.map(building => {
      if (building.constructionFinishedOn && dateHelper.getDayDiff(building.constructionFinishedOn, currentDate) <= 0) {
        return `${building.address}: ab sofort`;
      } else if (building.constructionFinishedOn) {
        return `${building.address}: ${dateHelper.getDayDiff(building.constructionFinishedOn, currentDate) <= 365 ?
          dateHelper.formatMonthYear(building.constructionFinishedOn) :
          dateHelper.formatQuarterYear(building.constructionFinishedOn)}`;
      }
      return '';
    }).join(', '));

  const projectUrl = `/projekte/${encodeURIComponent(project.alias || project.id)}${returnUrl ? `?returnUrl=${returnUrl}` : ''}`;

  return (
    <DashboardTile className="dashboardTile" tileWidth={tileWidth}>
      <ProjectImageWrapper hover={tileHover} onMouseEnter={() => setTileHover(!tileHover)} onMouseLeave={() => setTileHover(!tileHover)}>
        <StLink to={projectUrl}>
          <ProjectImage src={project.mainPhotoUrl ? addToPublicFileUrl(project.mainPhotoUrl, { height: 460 }) : projectFallbackImg} x={project.mainPhotoFocalPointX || 0} y={project.mainPhotoFocalPointY || 0} />
        </StLink>
      </ProjectImageWrapper>
      <ProjectInfo>
        <ProjectLink to={projectUrl}>
          <Address>
            {project.cityPostCode}<br />
            {project.street}
          </Address>
        </ProjectLink>
        <ProjectProposalTypes>
          {project.proposalTypes}
        </ProjectProposalTypes>
        <ProjectOtherInfo>
          {(project.numberOfLivingUnits != null && !!project.numberOfOnlineLivingUnits) &&
            <p>Wohnungen: <strong>{project.numberOfOnlineLivingUnits} / {project.numberOfLivingUnits} verfügbar</strong></p>}
          <p>{constructionDatesAndAddresses}</p>
        </ProjectOtherInfo>
        <ProjectButton onMouseEnter={() => setTileHover(!tileHover)} onMouseLeave={() => setTileHover(!tileHover)}>
          <PrimaryLinkButton to={projectUrl} className={tileHover ? 'hover' : ''}>Zum projekt</PrimaryLinkButton>
        </ProjectButton>
      </ProjectInfo>
    </DashboardTile>

  );
};

interface Props {
  project: ProjectTile;
  tileWidth?: number;
  returnUrl?: string;
}

export interface ProjectTile {
  id: number;
  alias: string | null;
  cityPostCode: string;
  numberOfLivingUnits: number | null;
  numberOfOnlineLivingUnits: number | null;
  mainPhotoUrl: string | null;
  mainPhotoFocalPointX: number | null;
  mainPhotoFocalPointY: number | null;
  constructionFinishedOn: Date | null;
  street: string;
  buildingsConstructionDates: BuildingConstructionDate[];
  proposalTypes: string;
}

interface BuildingConstructionDate {
  address: string;
  constructionFinishedOn: Date | null;
}

export default ProjectDashboardTile;